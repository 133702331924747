import React, { useRef, useEffect } from 'react';
import {
  useMultiStyleConfig,
  Box,
  Heading,
  Flex,
  VStack,
  Show,
  useMediaQuery
} from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import ButtonGroup from '@components/molecules/ButtonGroup';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger, CustomEase);
}

const HomeHero = ({ data: { titleImage, leftImage, image, video, ctas } }) => {
  const [shortViewportHeight] = useMediaQuery(`(max-height: 840px)`);

  const containerRef = useRef(null);
  const titleImageRef = useRef(null);
  const imageRef = useRef(null);
  const btnGroupRef = useRef(null);
  const styles = useMultiStyleConfig(`homeHero`);
  useEffect(() => {
    if (titleImageRef.current != null) {
      gsap.to(titleImageRef.current, {
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: `power1.out`,
        delay: 1,
        scrollTrigger: {
          trigger: containerRef.current,
          start: `top center`
        }
      });
    }
    if (imageRef.current != null) {
      gsap.to(imageRef.current, {
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: `power1.out`,
        delay: 1.5,
        scrollTrigger: {
          trigger: containerRef.current,
          start: `top center`
        }
      });
    }
    if (btnGroupRef.current != null) {
      gsap.to(btnGroupRef.current, {
        opacity: 1,
        duration: 0.6,
        ease: `power1.out`,
        delay: 2,
        scrollTrigger: {
          trigger: containerRef.current,
          start: `top center`
        }
      });
    }
  }, []);
  return (
    <Flex
      as="section"
      ref={containerRef}
      position="relative"
      minHeight={{ lg: `44rem` }}
      height={{ lg: `100vh` }}>
      <Box
        position="relative"
        display="flex"
        align="center"
        height="100%"
        width={{ base: `100%`, lg: `50%`, xl: `43%` }}
        pt={headerHeight}
        pb={{ base: 12, lg: 28 }}
        __css={styles.homeHeroBackground}>
        <Wrapper px={0}>
          <VStack alignItems="center" spacing="8">
            {titleImage && (
              <Box
                px={6}
                width="100%"
                ref={titleImageRef}
                opacity={0}
                scale={0.8}
                transform="auto">
                <Heading
                  as="h1"
                  width="100%"
                  maxW={shortViewportHeight ? { lg: `20rem` } : `27.1875rem`}
                  mx="auto">
                  <Image image={titleImage} loading="lazy" />
                </Heading>
              </Box>
            )}
            {leftImage && (
              <Box
                ref={imageRef}
                opacity={0}
                scale={0.8}
                transform="auto"
                width="100%"
                maxW={shortViewportHeight ? { lg: `25rem` } : `38.625rem`}>
                <Image image={leftImage} loading="lazy" />
              </Box>
            )}
            {ctas && ctas.length > 0 && (
              <Box
                ref={btnGroupRef}
                opacity={0}
                willChange="opacity"
                width="100%"
                px={{ base: 6, xl: `12%` }}>
                <ButtonGroup
                  ctas={ctas}
                  justify="center"
                  dataLayerType="home_hero"
                  buttonWidth={{ lg: `calc(50% - 1.75rem)` }}
                  size="sm"
                />
              </Box>
            )}
          </VStack>
        </Wrapper>
      </Box>
      <Show above="lg">
        <Flex
          width={{ lg: `50%`, xl: `57%` }}
          height={{ lg: `100%` }}
          position="relative"
          sx={{
            '> .gatsby-image-wrapper': {
              flexGrow: 1
            }
          }}>
          {video && (
            <Box
              as="video"
              src={video}
              controls={false}
              autoPlay
              loop
              muted
              playsInline
              objectFit="cover"
              width="100%"
              height="100%"
            />
          )}
          {!video && image && <Image image={image} loading="eager" />}
        </Flex>
      </Show>
    </Flex>
  );
};

export default HomeHero;
